import MainFooter, { FooterProps } from '@/components/MainFooter';
import MainHeader, { HeaderProps } from '@/components/MainHeader';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

type MainLayoutProps = PropsWithChildren<{
    fixedHeader?: boolean;
    footer?: FooterProps['variant'] | 'hidden';
    header?: HeaderProps['variant'] | 'hidden';
}>;

export const MainLayout = ({ children, fixedHeader = true, header = 'default', footer = 'default' }: MainLayoutProps) => {
    return (
        <>
            <div className={`${fixedHeader ? 'fixed' : 'relative'} top-0 right-0 left-0 bg-white z-20`}>
                {header !== 'hidden' && <MainHeader variant={header} />}
            </div>
            <main className={'flex flex-1 w-full flex-col md:mt-[78px] mt-[67px] bg-white md:bg-[#F7FDFF]'}>
                <motion.div
                    className={'flex flex-1 flex-col'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.3,
                        type: 'spring',
                        stiffness: 100,
                        damping: 20,
                    }}
                >
                    {children}
                </motion.div>
            </main>
            {footer !== 'hidden' && <MainFooter variant={footer} />}
        </>
    );
};
