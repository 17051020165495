import { Content } from '@/components/shared/Content';
import Image from 'next/image';
import Link from 'next/link';
import { AiOutlineLock } from 'react-icons/ai';
import { MainLogo } from './MainLogo';

export type FooterProps = {
    variant: 'minimal' | 'default' | 'rent' | 'financial';
};

export default function MainFooter({ variant }: FooterProps) {
    if (variant == 'minimal') {
        return (
            <footer className="p-12 bg-[#00202A] text-white text-center flex flex-col justify-center items-center gap-5">
                <Image src="/images/clidrive-logotype-blue.svg" width={120} height={50} alt="Clidrive" />
                <ul>
                    <li>Clidrive Group</li>
                    <li className={'italic'}>Calle de María de Molina 39, Madrid, 28006</li>
                    <li className={'mt-[24px]'}>
                        <Link id="FT-TYC" className="underline" href="/aviso-legal">
                            Términos y condiciones
                        </Link>
                    </li>
                    <li className={'mt-[8px]'}>
                        <Link id="FT-PRIVACY" className="underline" href="/politica-de-privacidad">
                            Política de privacidad
                        </Link>
                    </li>
                </ul>
            </footer>
        );
    }
    if (variant == 'financial') {
        return (
            <footer className="p-12 bg-[#00202A] text-white text-center flex flex-col justify-center items-center gap-5">
                <Image src="/images/clidrive-logotype-blue.svg" width={120} height={50} alt="Clidrive" />
                <ul>
                    <div className="flex flex-col items-center gap-2">
                        <AiOutlineLock className="w-5 h-5" />
                        <p className="text-sm">Todos tus datos están cifrados y seguros</p>
                    </div>
                    <li className={'mt-[24px]'}>
                        <Link id="FT-TYC" className="underline" href="/aviso-legal">
                            Términos y condiciones
                        </Link>
                    </li>
                    <li className={'mt-[8px]'}>
                        <Link id="FT-PRIVACY" className="underline" href="/politica-de-privacidad">
                            Política de privacidad
                        </Link>
                    </li>
                </ul>
            </footer>
        );
    }

    if (variant == 'rent') {
        return (
            <footer className="grid md:grid-cols-4 p-8 py-24 gap-6 bg-cli-darkblue-2 text-white">
                <div className="space-y-4">
                    <Image src="/images/clidrive-logotype-blue.svg" width={120} height={50} alt="Clidrive" />
                    <Link className="inline-block my-4 text-blue-200 underline" href="/contact">
                        Contacto
                    </Link>
                    <p>
                        <i>
                            Calle de María de Molina, 39 <br />
                            28006 Madrid
                        </i>
                    </p>
                </div>
                <div>
                    <h2 className="text-blue-400">Horario</h2>
                    <ul>
                        <li className="my-4">
                            De Lunes a Viernes
                            <br />
                            de 09:00 - 19:00
                        </li>
                    </ul>
                </div>
                <div>
                    <div>
                        <h2 className="text-blue-400">Teléfono</h2>
                        <a
                            id="FT-PHONE"
                            className="underline text-blue-300 my-2 inline-block"
                            href="tel:+34919497044"
                            target="_blank"
                            rel="noreferrer"
                        >
                            +34 919 49 70 44
                        </a>
                    </div>
                </div>
                <ul className="space-y-4 text-blue-200 underline">
                    <li>
                        <Link id="FT-COOKIES" href="/politica-de-cookies">
                            Política de Cookies
                        </Link>
                    </li>
                    <li>
                        <Link id="FT-PRIVACY" href="/politica-de-privacidad">
                            Política de Privacidad
                        </Link>
                    </li>
                    <li>
                        <Link id="FT-TYC" href="/aviso-legal">
                            Aviso Legal y Términos de uso
                        </Link>
                    </li>
                </ul>
            </footer>
        );
    }

    return (
        <footer className={'bg-azure-900'}>
            <Content className="grid md:grid-cols-4 p-8 py-24 gap-6 text-white m-auto">
                <div className="space-y-4">
                    <MainLogo variant="new" />
                    <p>Clidrive Group</p>
                    <p>
                        <i>
                            Calle de María de Molina, 39 <br />
                            28006 Madrid
                        </i>
                    </p>
                </div>
                <div className="space-y-4">
                    <div>
                        <h2 className="font-bold">Horario atención al cliente</h2>
                        <ul>
                            <li>
                                De Lunes a Viernes
                                <br />
                                de 09:00 - 20:30
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="space-y-4">
                    <div>
                        <h2 className="font-bold">Contacto</h2>
                        <a id="FT-PHONE" className="inline-block italic" href="tel:+34911436459" target="_blank" rel="noreferrer">
                            +34 911 43 64 59
                        </a>
                    </div>
                    <div>
                        <h2 className="font-bold">Comunicación y Prensa</h2>
                        <a id="FT-COMM" href="mailto:contacto@clidrive.com" className="my-2 inline-block underline">
                            contacto@clidrive.com
                        </a>
                    </div>
                </div>
                <div>
                    <h2 className="font-bold">Legal</h2>
                    <ul className="space-y-2 underline">
                        <li>
                            <Link id="FT-COOKIES" href="/politica-de-cookies">
                                Política de Cookies
                            </Link>
                        </li>
                        <li>
                            <Link id="FT-PRIVACY" href="/politica-de-privacidad">
                                Política de Privacidad
                            </Link>
                        </li>
                        <li>
                            <Link id="FT-TYC" href="/aviso-legal">
                                Aviso Legal y Términos de uso
                            </Link>
                        </li>
                        <li>
                            <Link id="FT-TYC" href="/terminos-condiciones">
                                Términos y Condiciones
                            </Link>
                        </li>
                    </ul>
                    <div className="mt-6 space-y-2">
                        <h2 className="font-bold">Canal de Ética y Reclamaciones</h2>
                        <a id="FT-COMM" href="https://forms.gle/GoxW8mkojCy4e4jX7" className="my-2 inline-block underline">
                            Buzón de reclamaciones
                        </a>
                    </div>
                </div>
            </Content>
        </footer>
    );
}
