import { Header } from '@/components/shared/Header';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { AnimatePresence, motion, SVGMotionProps, Transition } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { HiOutlinePhone } from 'react-icons/hi';
import { MainLogo } from './MainLogo';

// export const MenuItemLabel = ({ label, href, variant = 'old' }: { label: string; href?: string; variant?: 'new' | 'old' }) => {
//     const router = useRouter();
//     const isActive = router.pathname === href;
//
//     return (
//         <span
//             className={`text-lg leading-5 px-3 py-1.5 m-auto hover:cursor-pointer hover:scale-105 active:scale-90 transition-all whitespace-nowrap ${
//                 isActive && variant === 'old' ? 'active-route' : ''
//             } ${variant === 'new' ? 'text-white font-700' : 'text-zodiac-500 hover:text-[#00ADDF] font-400'}`}
//         >
//             {label}
//         </span>
//     );
// };
//
// type MenuItemArgs = {
//     href?: string;
//     label: string;
//     variant?: 'new' | 'old';
// };
// export function MenuItem({ label, children, href, variant = 'old' }: PropsWithChildren<MenuItemArgs>) {
//     if (href) {
//         return (
//             <Link href={href} data-testid={label}>
//                 <MenuItemLabel label={label} href={href} variant={variant} />
//             </Link>
//         );
//     }
//     if (children) {
//         return (
//             <>
//                 <div className="group relative">
//                     <MenuItemLabel label={label} />
//                     <div className="absolute -translate-x-[30%] block opacity-0 group-hover:opacity-100 translate-y-10 group-hover:translate-y-0 bg-white shadow-xl rounded-[40px] px-12 py-8 transition-all pointer-events-none group-hover:pointer-events-auto min-w-max duration-200">
//                         <div className="z-10">{children}</div>
//                     </div>
//                 </div>
//             </>
//         );
//     }
//     return <></>;
// }

interface Props extends SVGMotionProps<SVGElement> {
    isOpen?: boolean;
    color?: string;
    strokeWidth?: string | number;
    transition?: Transition;
    lineProps?: any;
}

const MobileMenuIcon = ({
    isOpen = false,
    width = 18,
    height = 18,
    strokeWidth = 2,
    color = '#FFF',
    transition = { duration: 0.3, type: 'spring' },
    lineProps = null,
    ...props
}: Props) => {
    const variant = isOpen ? 'opened' : 'closed';
    const top = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: 45,
            translateY: 1,
        },
    };
    const center = {
        closed: {
            opacity: 1,
        },
        opened: {
            opacity: 0,
        },
    };
    const bottom = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: -45,
            translateY: -3,
            translateX: 0,
        },
    };
    lineProps = {
        stroke: color,
        strokeWidth: strokeWidth as number,
        vectorEffect: 'non-scaling-stroke',
        initial: 'closed',
        animate: variant,
        transition,
        ...lineProps,
    };
    const unitHeight = 6;
    const unitWidth = 6;

    return (
        <div className="flex items-center pt-1">
            <motion.svg
                viewBox={`0 0 ${unitWidth} ${unitHeight}`}
                overflow="visible"
                preserveAspectRatio="none"
                width={width}
                height={height}
                {...props}
            >
                <motion.line x1="0" x2={unitWidth} y1="0" y2="0" variants={top} {...lineProps} />
                <motion.line x1="0" x2={unitWidth} y1="2" y2="2" variants={center} {...lineProps} />
                <motion.line x1="0" x2={unitWidth} y1="4" y2="4" variants={bottom} {...lineProps} />
            </motion.svg>
        </div>
    );
};

function PhoneLink() {
    const phoneNumber = process.env.NEXT_PUBLIC_CONTACT_PHONE;
    const phoneLink = `tel:${phoneNumber?.replace(/\s/g, '')}`;
    const phoneLinkVariants = {
        initial: {
            scale: 1,
        },
        hover: {
            scale: 1.2,
            rotate: [0, 10, -10, 10, -10, 10, -10, 0],
            transition: {
                type: 'spring',
                duration: 0.7,
            },
        },
        tap: {
            scale: 0.9,
            transition: {
                type: 'spring',
                duration: 0.2,
            },
        },
    };
    return (
        <motion.div variants={phoneLinkVariants} whileHover="hover" whileTap="tap">
            <Link
                title="Llamar por teléfono"
                className="flex text-slate-600 hover:text-slate-900 items-center px-3 py-1.5 h-full rounded-md"
                href={phoneLink}
            >
                <Image src={'/icons/telephone.svg'} alt={'Telephone icon'} width={24} height={24} />
            </Link>
        </motion.div>
    );
}

type LinkItem = {
    label: string;
    href: string;
    id?: string;
    target?: string;
};
type LinkTree = {
    section: string;
    links: LinkItem[] | LinkItem;
};
type NavMenuProps = {
    linkTree: LinkTree[];
    logoHref: string;
    variant?: 'new' | 'old';
    hidePhoneLink?: boolean;
    showCTA?: boolean;
};

export function NavMenu({ linkTree, variant = 'old', hidePhoneLink, showCTA }: NavMenuProps) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (variant === 'old') {
            const rootEl = document.documentElement;
            if (showMobileMenu) {
                rootEl?.classList.add('overflow-hidden');
            } else {
                rootEl?.classList.remove('overflow-hidden');
            }
        }
    }, [showMobileMenu, variant]);

    useEffect(() => {
        setShowMobileMenu(false);
    }, [router]);

    const headerCtaVariants = {
        hide: { opacity: 0, visibility: 'hidde' as any, transitionEnd: { visibility: 'hidden' as any } },
        show: {
            opacity: 1,
            visibility: 'visible' as any,
            transition: {
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <Header className={`flex items-center h-full md:justify-between gap-4 justify-center`} variant={variant}>
            <div className={`${variant === 'new' ? 'grid grid-cols-3 gap-4 md:flex' : 'flex justify-between items-center w-full'}`}>
                <MainLogo variant={variant === 'old' ? 'new' : 'application-form'} />
                {/* 📱 Mobile menu bar */}
                <div className="md:hidden flex items-center justify-end">
                    <button
                        id="mobile-menu"
                        title="Abrir menú de navegación"
                        className="p-3"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                        <MobileMenuIcon isOpen={showMobileMenu} color={variant === 'old' ? '#FFF' : '#000'} />
                    </button>
                </div>

                {/* 📱 Mobile menu */}
                <AnimatePresence>
                    {showMobileMenu && (
                        <motion.div
                            initial={{ opacity: 0, height: '0px' }}
                            animate={{ opacity: 1, height: '100%' }}
                            exit={{ opacity: 0, height: '0px' }}
                            transition={{ duration: 0.1, ease: 'easeInOut' }}
                            className={`fixed h-fit md:hidden
                             left-0 w-full top-[67px] pb-[16px] ${variant === 'old' ? 'bg-lavender-100' : 'bg-azure-600'}`}
                        >
                            <div
                                className={`text-lg flex flex-col ${
                                    variant === 'old' ? 'text-black divide-y' : 'text-white items-center font-bold gap-4'
                                }`}
                            >
                                <Accordion allowToggle defaultIndex={0}>
                                    {linkTree.map((item) =>
                                        Array.isArray(item.links) ? (
                                            <AccordionItem key={item.section} border="0">
                                                {({ isExpanded }) => (
                                                    <div>
                                                        <h2 className={'bg-azure-600 text-white'}>
                                                            <AccordionButton>
                                                                <Box
                                                                    id="HM-HEADER-US"
                                                                    as="span"
                                                                    flex="1"
                                                                    textAlign="left"
                                                                    fontSize={'xl'}
                                                                    fontWeight={isExpanded ? 'bold' : ''}
                                                                >
                                                                    {item.section}
                                                                </Box>
                                                                <AccordionIcon className={''} color={'white'} />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel p={0}>
                                                            {/*@ts-ignore*/}
                                                            {item.links.map((subItem) => (
                                                                <Link
                                                                    id={subItem.id}
                                                                    key={subItem.href}
                                                                    className={`py-2 px-4 flex items-center text-azure-800 hover:bg-gray-100 ${
                                                                        variant === 'old' ? 'justify-between' : 'justify-center'
                                                                    }`}
                                                                    href={subItem.href}
                                                                    target={subItem.target}
                                                                >
                                                                    {subItem.label}
                                                                </Link>
                                                            ))}
                                                        </AccordionPanel>
                                                    </div>
                                                )}
                                            </AccordionItem>
                                        ) : (
                                            <Link
                                                id={item.links.id}
                                                key={item.links.href}
                                                className={`py-2 px-4 flex items-center text-xl ${
                                                    variant === 'old' ? 'justify-between hover:bg-[#edf3f5]' : 'justify-center'
                                                }`}
                                                href={item.links.href}
                                                target={item.links.target}
                                            >
                                                {item.links.label}
                                            </Link>
                                        ),
                                    )}
                                </Accordion>

                                {variant === 'new' && (
                                    <Button
                                        variant={'primary'}
                                        width={'fit-content'}
                                        className={'mt-[24px] bg-white h-[52px] text-azure-500 font-500 leading-[1.5rem] rounded-[8px]'}
                                        onClick={() => router.push('/lead-form')}
                                    >
                                        {'Solicitar evaluación gratuita'}
                                    </Button>
                                )}
                                <div className="bg-azure-600 flex border-none">
                                    <Button
                                        id={'HM-HEADER-PHONE'}
                                        variant={'empty'}
                                        className={'w-full'}
                                        onClick={() => router.push('tel:911239287')}
                                    >
                                        <HiOutlinePhone className={'w-6 h-6 text-white'} />
                                    </Button>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {variant === 'new' ? (
                    <div className="md:hidden order-3 flex items-center">
                        <AnimatePresence>
                            <motion.div
                                variants={headerCtaVariants}
                                transition={{ duration: 0.1 }}
                                initial={'show'}
                                animate={!showMobileMenu ? 'show' : 'hide'}
                            >
                                <Button
                                    variant={'primary'}
                                    width={'fit-content'}
                                    padding={'0 12px'}
                                    className={''}
                                    onClick={() => router.push('/lead-form')}
                                >
                                    {'Solicitar'}
                                </Button>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                ) : null}
            </div>
            {/* 💻 Desktop menu */}
            <div className="md:flex gap-1 items-center hidden">
                {/*{linkTree.map((item) => (*/}
                {/*    <MenuItem {...item} key={item.href} href={item.href} label={item.label} variant={variant} />*/}
                {/*))}*/}
                <div className={'flex items-center gap-2'}>
                    {linkTree.map((item) =>
                        Array.isArray(item.links) ? (
                            <Menu key={item.section} id=" HM-HEADER-US">
                                <MenuButton
                                    as={Button}
                                    textColor={'white'}
                                    bg={'none'}
                                    _hover={{
                                        background: 'rgba(0, 0, 0, 0.04)',
                                    }}
                                    fontWeight={'normal'}
                                    rightIcon={<ChevronDownIcon />}
                                >
                                    {item.section}
                                </MenuButton>
                                <MenuList>
                                    {/*@ts-ignore*/}
                                    {item.links.map((subItem) => (
                                        <MenuItem key={subItem.href}>
                                            <Link
                                                id={subItem.id}
                                                key={subItem.href}
                                                className={`py-2 px-4 flex items-center text-azure-800 w-full ${
                                                    variant === 'old' ? 'justify-between' : 'justify-center'
                                                }`}
                                                href={subItem.href}
                                                target={subItem.target}
                                            >
                                                {subItem.label}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        ) : (
                            <Link
                                key={item.links.href}
                                className={`flex items-center ${variant === 'old' ? 'justify-between' : 'justify-center'} `}
                                href={!(router.pathname.includes(item.links.href) && item.links.href.length > 1) ? item.links.href : '#'}
                                target={item.links.target}
                                id={item.links.id}
                            >
                                <span
                                    className={`${
                                        variant === 'old' &&
                                        !(router.pathname.includes(item.links.href) && item.links.href.length > 1) &&
                                        router.pathname !== item.links.href &&
                                        'hover:bg-black/[.04] py-2 px-4 rounded-lg text-white '
                                    } ${
                                        variant === 'old' &&
                                        router.pathname.includes(item.links.href) &&
                                        item.links.href.length > 1 &&
                                        'text-white font-bold py-2 px-4 rounded-lg cursor-default'
                                    } ${
                                        variant === 'old' &&
                                        router.pathname === item.links.href &&
                                        item.links.href === '/' &&
                                        'text-white font-bold py-2 px-4 rounded-lg cursor-default'
                                    } ${
                                        ((router.pathname.includes(item.links.href) && item.links.href.length > 1) ||
                                            (item.links.href === '/' && router.pathname === item.links.href)) &&
                                        'bg-azure-500 font-bold'
                                    }`}
                                >
                                    {item.links.label}
                                </span>
                            </Link>
                        ),
                    )}
                </div>
                {!hidePhoneLink && <PhoneLink />}
                {showCTA && (
                    <Button
                        variant={'primary'}
                        width={'fit-content'}
                        fontSize={'sm'}
                        padding={'24px 16px'}
                        className={'ml-4'}
                        onClick={() => router.push('/lead-form')}
                    >
                        {'Solicitar evaluación gratuita'}
                    </Button>
                )}
            </div>
        </Header>
    );
}
