import { isValidPhoneNumber } from '@/lib/validators/phoneValidator';
import { preprocessEmptyToNull } from '@/lib/validators/zodValidators';
import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { z } from 'zod';
import Link from 'next/link';
import React from 'react';
import { RENTING_MARKETPLACE_URL } from '@/constants/urls';

interface DialogProps {
    readonly showModal: boolean;
    readonly closeDialog: () => void;
}

const validationSchema = z
    .object({
        email: preprocessEmptyToNull(
            z
                .string()
                .email({
                    message: 'El email no es válido',
                })
                .nullable(),
        ),
        phone: preprocessEmptyToNull(
            z
                .string()
                .min(9, 'El teléfono debe tener al menos 9 caracteres')
                .max(25, 'El teléfono debe tener máximo 25 caracteres')
                .refine((value) => isValidPhoneNumber(value), {
                    message: 'El teléfono no es válido',
                })
                .nullable(),
        ),
        name: preprocessEmptyToNull(z.string().trim().nullable()),
        surname: preprocessEmptyToNull(z.string().trim().nullable()),
        termsAndConditions: z.boolean().refine((value) => value, {
            message: 'Debes aceptar los términos y condiciones',
        }),
    })
    .superRefine((data, ctx) => {
        if (data.email === null && data.phone === null) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Introduce un email o un teléfono',
                path: ['email'],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Introduce un email o un teléfono',
                path: ['phone'],
            });
        }
    });

type ValidationSchema = z.infer<typeof validationSchema>;

const formLabelStyleProps = {
    fontSize: '12px',
    lineHeight: '16px',
    ml: '12px',
    mb: '4px',
    fontWeight: 400,
    letterSpacing: '0.4px',
};
const formHeightStyleProps = '!min-h-0';

export function RentingClientDetailsDialog({ showModal, closeDialog }: DialogProps) {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useForm<ValidationSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(validationSchema),
    });

    async function handleFormSubmit(formValues: ValidationSchema) {
        await fetch('/api/v1/renting/lead-registration', {
            method: 'POST',
            body: JSON.stringify(formValues),
        });

        window.open(RENTING_MARKETPLACE_URL, '_blank');
        closeDialog();
    }
    if (!showModal) return null;

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 h-screen md:h-auto flex md:items-center md:justify-center z-50 overflow-y-auto md:overflow-y-hidden ${
                window.innerHeight >= 650 && 'items-center'
            }`}
        >
            <div className="bg-white rounded-lg p-4 lg:p-8 max-w-full sm:max-w-sm md:max-w-xl xl:max-w-3xl h-fit w-auto relative">
                <button onClick={closeDialog} className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" aria-label="Cerrar">
                    <HiX className="w-6 h-6" />
                </button>
                <div className="flex flex-col gap-2 items-center">
                    <h2 className="text-lg lg:text-2xl 2xl:text-2xl text-azure-600 font-bold text-center mb-2">
                        Ayúdanos a conocerte mejor
                    </h2>
                    <div className="text-sm lg:text-md text-center space-y-2 lg:space-y-0 lg:pb-2">
                        <p>Rellena los siguientes datos para redirigirte a nuestro catálogo de Renting.</p>
                        <p className="font-semibold">No recibirás mensajes publicitarios ni promociones no solicitadas.</p>
                    </div>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="my-0 space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 lg:my-4">
                            <FormControl className={formHeightStyleProps} isInvalid={Boolean(errors.name)}>
                                <FormLabel {...formLabelStyleProps}>Nombre</FormLabel>
                                <Input
                                    className={formHeightStyleProps}
                                    type={'text'}
                                    size={'md'}
                                    inputMode={'text'}
                                    disabled={isSubmitting}
                                    id={'name'}
                                    {...register('name')}
                                />
                                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl className={formHeightStyleProps} isInvalid={Boolean(errors.surname)}>
                                <FormLabel {...formLabelStyleProps}>Apellido</FormLabel>
                                <Input
                                    className={formHeightStyleProps}
                                    type={'text'}
                                    inputMode={'text'}
                                    disabled={isSubmitting}
                                    id={'surname'}
                                    {...register('surname')}
                                />
                                <FormErrorMessage>{errors.surname?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl className={formHeightStyleProps} isInvalid={Boolean(errors.phone)}>
                                <FormLabel {...formLabelStyleProps}>Teléfono</FormLabel>
                                <Input
                                    className={formHeightStyleProps}
                                    type={'text'}
                                    inputMode={'tel'}
                                    placeholder={'+34666666666'}
                                    disabled={isSubmitting}
                                    id={'phone'}
                                    {...register('phone')}
                                />
                                <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl className={formHeightStyleProps} isInvalid={Boolean(errors.email)}>
                                <FormLabel {...formLabelStyleProps}>Correo electrónico</FormLabel>
                                <Input
                                    className={formHeightStyleProps}
                                    type={'text'}
                                    inputMode={'email'}
                                    placeholder={'example@gmail.com'}
                                    disabled={isSubmitting}
                                    id={'email'}
                                    {...register('email')}
                                />
                                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                                className={`${formHeightStyleProps} md:col-span-2 lg:px-8`}
                                isInvalid={Boolean(errors.termsAndConditions)}
                            >
                                <Checkbox
                                    className="hover:cursor-default pt-4 mt-2 transition"
                                    data-testid="termsAndConditions"
                                    {...register('termsAndConditions')}
                                >
                                    <p className="font-400 text-[12px] lg:text-[14px] leading-[20px] text-zodiac-500">
                                        He leído y acepto que mis datos sean cedidos a terceros con fines comerciales conforme a la{' '}
                                        <Link
                                            target="_blank"
                                            className="underline hover:text-blue-600 transition"
                                            href="/politica-de-privacidad"
                                        >
                                            Política de Privacidad
                                        </Link>{' '}
                                        de Clidrive.
                                    </p>
                                </Checkbox>
                                <FormErrorMessage>{errors.termsAndConditions?.message}</FormErrorMessage>
                            </FormControl>
                        </div>

                        <Button
                            id="RentingClientDetailsButton"
                            className="w-full mt-4 lg:mt-4"
                            variant={'primary'}
                            type="submit"
                            isLoading={isSubmitting}
                            isDisabled={!isDirty}
                        >
                            Ver catálogo renting
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}
